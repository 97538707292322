$(document).on('click', '.js-post-doubleclick', function (){
  const form = $(this).parents('form:first');
  const button = $(this);
  button.html('Please Wait...');
  button.prop('disabled', true);
  form.submit();
  return true;
});
$(document).on('click', '.js-btn-ajax', function (event){
  event.preventDefault();

  const button = $(this);
  const url = button.attr('data-url');
  const remove_container = getElement(button.data('deletecontainer'));
  const resultContainer = getElement(button.data('resultcontainer'));
  const javascriptCallback = button.data('callback');
  const activeContainer = button.data('activecontainer');
  let javascriptAdditionalCallbacks = button.data('additionalcallbacks');
  let ajaxMethod = 'POST';
  if (typeof button.data('method') !== 'undefined'){
    ajaxMethod = button.data('method');
  }
  const parent = getElement(button.data('parent'));
  const externalPageParent = button.parent();
  if ($('.has-tip').length){
    try {
      $('.has-tip').foundation('hide');
    } catch (err){}
  }
  if (javascriptAdditionalCallbacks){
    javascriptAdditionalCallbacks = javascriptAdditionalCallbacks.split(',');
  } else {
    javascriptAdditionalCallbacks = []
  }
  $.ajax({
    method: ajaxMethod,
    url: url,
    beforeSend: function (){
      button.prop('disabled', true);
      blockElement(parent);
      blockElement(resultContainer);
    },
    success: function (response){
      const element = $(response.responseView);
      if (remove_container){
        remove_container.remove();
      } else if (resultContainer){
        resultContainer.html(response.responseView);
      } else if (parent){
        parent.replaceWith(element);
      } else if (externalPageParent) {
        externalPageParent.replaceWith(element);
      } else {
        button.replaceWith(element)
      }
      if (response.flashMessage){
        FlashMessage.showShortMessage(response.flashMessage);
      }
    },
    complete: function (result){
      button.prop('disabled', false);
      unblockElement(parent);
      unblockElement(resultContainer);
      if ($('.has-tip').length){
        $('.has-tip').foundation();
      }
      if (undefined !== javascriptCallback){
        var func = window[javascriptCallback];
        if(activeContainer) {
          func(activeContainer);
        } else {
          func(result);
        }
      }
      for (let i = 0; i < javascriptAdditionalCallbacks.length; i++){
        var func = window[javascriptAdditionalCallbacks[i]];
        func();
      }

      if (result.pushUrl) {
        history.pushState({}, '', result.pushUrl)
      }
      if (result.pageTitle) {
        $(document).prop('title', result.pageTitle)
      }
    }
  });
});

/**
 * Used for loading content that menu item or button is referring to.
 *
 * (REQUIRED) @element {element} - Menu item / Button
 **** (REQUIRED) @class 'js-push-ajax' - Class that event is assigned to.
 **** (REQUIRED) @attribute data-url='{string}' - The url should return json response that contains responseView, pushUrl, pageTitle
 **** (REQUIRED) @attribute data-resultcontainer='{string}' - The container that content should be loaded to.
 **** (optional) @attribute data-menuid="{string}" - Id of the menu that should be updated according to the content.
 *
 * @example
 * <div class="js-push-ajax" data-url="{{ path('_institution_relationships', {'identifier': institution.shortName, 'relationshipType': relationship_type}) }}" data-resultcontainer="institution_list_box">
 *     View All
 * </div>
 */

$(document).on('click', '.js-push-ajax', function (event){
  event.preventDefault();

  const element = $(this);
  const url = element.attr('data-url');
  const menuId = element.attr('data-menuid');
  const menuObject = $("#"+menuId);
  let resultContainer = getElement(element.data('resultcontainer'));
  let pushUrl = null;
  let pageTitle = null;
  $.ajax({
    method: 'GET',
    url: url,
    beforeSend: function (){
      element.prop('disabled', true);
      // blockElement(resultContainer);
    },
    success: function (response){
      const responseViewHtml = response.responseView;
      resultContainer.replaceWith(responseViewHtml);
      resultContainer = getElement(element.data('resultcontainer'));
      if (response.pushUrl){
        pushUrl = response.pushUrl;
      }
      if (response.pageTitle) {
        pageTitle = response.pageTitle
      }
      if (response.flashMessage){
        FlashMessage.displayFlashMessages(response.flashMessage);
      }
      resultContainer.find('.js-ajaxLoadBox').each(function (i, obj){
        fillInvisibleContent($(this));
      });
      const menuItem = element.closest('li');
      menuItem.closest('nav').find('.selected').removeClass('selected');
      menuItem.addClass('selected');

      if (menuObject){
        $("#"+menuId+" li").each(function(i){
          $(this).removeClass('selected');
          const menuDiv = $(this).find(".js-push-ajax");
          const menuItemUrl = menuDiv.attr("data-url");
          if (url == menuItemUrl){
            $(this).addClass('selected');
          }

        });
      }
    },
    complete: function (result){
      element.prop('disabled', false);
      unblockElement(resultContainer);
      if (pushUrl){
        history.pushState({}, '', pushUrl);
      }
      if (pageTitle) {
        $(document).prop('title', pageTitle)
      }
    }
  });
});

$(document).on('click', '.js-btn-ajax-follow', function (event){
  event.preventDefault();
  const follow = $(this);
  const url = follow.attr('data-url');
  const remove_container = getElement(follow.data('deletecontainer'));
  const resultContainer = getElement(follow.data('resultcontainer'));
  const parent = getElement(follow.data('parent'));
  if ($('.has-tip').length){
    try {
      $('.has-tip').foundation('hide');
    } catch (err){}
  }
  $.ajax({
    method: 'POST',
    url: url,
    beforeSend: function (){
      follow.prop('disabled', true);
      blockElement(parent);
      blockElement(resultContainer);
    },
    success: function (response){
      if ($('.profile-data').has(follow).length){
        $('.profile-data').has(follow).remove();
      }
      refreshListWithPagination();
      const element = $(response.responseView);
      if (remove_container){
        remove_container.remove();
      } else if (resultContainer){
        resultContainer.html(element);
      } else if (parent){
        parent.replaceWith(element);
      } else {
        follow.replaceWith(element)
      }
    },
    complete: function (){
      follow.prop('disabled', false);
      unblockElement(parent);
      unblockElement(resultContainer);
      if ($('.has-tip').length){
        $('.has-tip').foundation();
      }
    }
  });
});

$(document).on('click', '.js-ajax-search-term-form-btn', function (event){
  event.preventDefault();
  const element = $(this);
  const url = element.attr('data-url');
  const resultContainer = getElement(element.data('resultcontainer'));
  const formContainer = getElement(element.data('parent'));
  const formData = element.closest('form').serialize();
  let method = element.data('method');
  if (typeof method === 'undefined'){
    method = 'POST';
  }

  $.ajax({
    method: method,
    url: url,
    data: formData,
    dataType: 'JSON',
    beforeSend: function (){
      element.prop('disabled', true);
      blockElement(resultContainer);
    },
    success: function (response){
      const responseView = $(response.responseView);

      if (response.success){
        $('.help-text--error').remove();
        resultContainer.html(responseView);
      } else {
        formContainer.html(responseView)
      }

      if (response.flashMessage) {
        FlashMessage.displayFlashMessages(response.flashMessage)
      }
    },
    complete: function () {
      element.prop('disabled', false)
      unblockElement(resultContainer)
      $('.tabs').foundation()
    }
  })
})

$(document).on('click', '.js-seeAllToggle', function (event){
  event.preventDefault();
  const element = $(this);
  const status = element.data('status');
  const toggleClass = element.data('toggleclass');
  const lessText = element.data('seelesstext');
  const allText = element.data('seealltext');
  $('.' + toggleClass).toggle();
  element.data('status', 1 - status);
  if (status == 1){
    element.text(allText);
  } else {
    element.text(lessText)
  }
})

$(document).on('click', '.js-selectable-text', function (){
  let range, selection;

  if (window.getSelection){
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(this);
    selection.removeAllRanges();
    selection.addRange(range);
  }
})

$(document).on('keypress paste keyup', '.onlyalphanumeric', function (e){
  const target = e.target;
  const position = target.selectionStart;
  const length = target.value.length;
  let newValue = target.value.replace(/_*$/, '');
  newValue = newValue.replace(/^[_]*/, '');
  newValue = newValue.replace(/\s/g, '');
  const regex = /#|\$|\%|\@|\^|\&|\*|\(|\)|\+|\}|\|\=|\"|\:|\?|\>|\<|\.|\!|\/|\||\~|\\|\,|\]|\[|'|\[|\=|\`|\{/g;
  newValue = newValue.replace(regex, '');
  target.value = newValue;
  target.selectionEnd = position - (length - target.value.length);
});

$(document).on('click', '.js-not-my-paper', function (){
  const button = $(this);
  const url = button.data('url');
  const loadingElement = $('#suggested_publications_wrap');

  loadingElement.attr('data-url', url);
  fillInvisibleContent(loadingElement);
});

$(document).on('click', '.js-ajax-event', function (e){
  e.preventDefault();

  const object = $(this);
  const confirmation = object.data('confirmtext');
  const content = object.data('content');
  const buttonText = object.data('buttontext');

  if (confirmation){
    $('<p></p>').confirm(
        confirmation,
        function (event){
          if (event.response){
            customAjaxCall(object);
          }
        },
        false,
        buttonText,
        'Cancel',
        content
    )
  } else {
    customAjaxCall(object)
  }
});

/**
 * Register form submit
 * @deprecated
 */
$(document).on('click', '.js-grecaptchaajax-submit', function (e){
  const button = $(this);
  button.prop('disabled', true);
  e.preventDefault();

  const object = $(this);
  const confirmation = object.data('confirmtext');
  const buttonText = object.data('buttontext');
  const recaptcha_site_key = object.data('recaptcha_site_key');
  const recaptcha_action = object.data('recaptcha_action');
  const recaptcha_token_field = object.data('recaptcha_fieldid');
  grecaptcha
      .execute(recaptcha_site_key, { action: recaptcha_action })
      .then(function (token){
        $('#' + recaptcha_token_field).val(token);
        if (confirmation){
          $('<p></p>').confirm(
              confirmation,
              function (event){
                if (event.response){
                  customAjaxSubmit(object);
                }
              },
              false,
              buttonText,
              'Cancel'
          );
        } else {
          customAjaxSubmit(object)
        }
      })
})

$(document).on('change', '.js-ajax-switch', function (event){
  const object = $(event.target);
  const ajaxUrl = object.data('url');
  object.prop('disabled', true);
  let flashMessage = null;

  $.ajax({
    type: 'POST',
    url: ajaxUrl,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (data){
      if (data.hasOwnProperty('flashMessage')){
        flashMessage = data.flashMessage;
      }
      if (data.responseView){
        ajaxPopupDialogForResponseView(data.responseView);
      }
    },
    complete: function () {
      if (flashMessage) {
        FlashMessage.showShortMessage(flashMessage);
      }
      object.prop('disabled', false);
    }
  });
});
$(document).on('change', '.js-paginator-rows-per-page', function (e){
  e.preventDefault();
  const object = $(e.target);
  let url = object.data('url');
  // var url = $(this).attr('url');
  const rowsperpage = 'rows=' + object.val();
  url += (url.indexOf('?') >= 0 ? '&' : '?') + rowsperpage;
  const container_id = $(this).data('container');
  const update_elem = $('#' + container_id);
  blockElement(update_elem);
  $.ajax({
    type: 'POST',
    url: url,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (result){
      if (update_elem.length > 0){
        update_elem.html(result.responseView);
      }
    },
    complete: function (){
      unblockElement(update_elem);
    }
  });
});
$(document).on('change', '.js-rows-per-page', function (event){
  const object = $(event.target);
  const url = object.data('url');
  const container_id = object.data('container');
  const update_elem = $('#' + container_id);
  const fullUrl = url + '/1/' + object.val();
  blockElement(update_elem);
  $.ajax({
    type: 'POST',
    url: fullUrl,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (result){
      if (update_elem.length > 0){
        update_elem.html(result.responseView);
      }
    },
    complete: function (){
      unblockElement(update_elem);
    }
  });
});
$(document).on('click', '.js-scroll-to', function (e){
  e.preventDefault();

  const object = $(this);
  const scrollToId = object.data('scrollto');
  const scrollToObject = $('#' + scrollToId);
  if (scrollToObject){
    scrollToElement(scrollToObject);
  }
})

function customAjaxSubmit(object){
  object.prop('disabled', true);
  const url = object.data('url');
  const javascriptCallback = object.data('callback');
  const container_id = object.data('container');
  const containerElem = $('#' + container_id);
  let flashMessage = null;
  let clearForm = true;
  const update_elem = null;
  const form = object.parents('form:first');
  const Data = new FormData();
  $.each($('input[type="file"]'), function (i, file_elem){
    Data.append($(file_elem).attr('name'), file_elem.files[0]);
  });
  $.each(form.serializeArray(), function (i, elem){
    Data.append(elem.name, elem.value);
  });

  // Is the form register before version or register after
  let registerFormAfter = false;
  if ($('.register-form')[0]){
    registerFormAfter =
        (' ' + $('.register-form')[0].className + ' ').indexOf(
            ' register-form-after '
        ) > -1;
  }

  $.ajax({
    type: 'POST',
    url: url,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    data: Data,
    success: function (data){
      if (data.hasOwnProperty('reloadPage') && data.reloadPage){
        location.reload();
      }
      if (data.hasOwnProperty('goToUrl') && data.goToUrl){
        window.location.href = data.goToUrl;
      }
      if (data.hasOwnProperty('clearForm') && data.clearForm){
        clearForm = data.clearForm;
      }
      if (data.hasOwnProperty('flashMessage')){
        flashMessage = data.flashMessage;
      }
      if (!data.success){
        if (containerElem.length){
          // Setting form type to before or after
          if (registerFormAfter){
            const responseParsed = data.responseView.replace(
                /register-form-before/g,
                'register-form-after'
            );
            containerElem.html(responseParsed);
          } else {
            containerElem.html(data.responseView)
          }
        }
      } else {
        if (data.responseView){
          ajaxPopupDialogForResponseView(data.responseView);
        }
      }
      if (data.responseForm && containerElem.length){
        containerElem.html(data.responseForm);
      }
      if (undefined !== javascriptCallback){
        const func = window[javascriptCallback];
        func(object, data);
      }
    },
    complete: function (){
      if (flashMessage){
        FlashMessage.showFlashMessages(
            flashMessage.message,
            flashMessage.messageType
        )
      }
      object.prop('disabled', false);
      if (clearForm){
        form[0].reset();
      }
    }
  });
}
function customAjaxAction(url, object){
  object.prop('disabled', true);
  const container_id = object.data('container');
  const containerElem = $('#' + container_id);
  let flashMessage = null;

  $.ajax({
    type: 'POST',
    url: url,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (data){
      if (data.hasOwnProperty('flashMessage')){
        flashMessage = data.flashMessage;
      }
      if (data.success && containerElem.length){
        containerElem.html(data.responseView);
      } else if (!data.success){
        ajaxPopupDialogForResponseView(data.responseView);
      }
    },
    complete: function (){
      if (flashMessage){
        FlashMessage.showFlashMessages(
            flashMessage.message,
            flashMessage.messageType
        )
      }
      object.prop('disabled', false);
    }
  });
}

/**
 * @deprecated
 */
$(document).on('click', '.js-recaptcha-action', function (){
  let url = $(this).data('url');
  const recaptcha_site_key = $(this).data('recaptcha_site_key');
  const recaptcha_action = 'validateaction';
  const buttonObject = $(this);
  grecaptcha
      .execute(recaptcha_site_key, { action: recaptcha_action })
      .then(function (token){
        url = url + '/' + token;
        customAjaxAction(url, buttonObject);
      });
});

$(document).on('click', '.js-tab', function (){
  const tab = $(this);
  const contentUrl = tab.data('url');
  const tabContent = $('.js-tab-content');

  if (!tab.hasClass('profile-nav__item--active') || tab.hasClass('reactive-tab')){
    $.ajax({
      type: 'GET',
      url: contentUrl,
      success: function (result){
        if (result.success){
          tabContent.html(result.responseView);
          $('.profile-nav__item--active').removeClass('profile-nav__item--active');
          tab.addClass('profile-nav__item--active');

          const tabAjaxContainer = $('.js-tab-content-ajax');
          const tabAjaxContainerUrl = tabAjaxContainer.data('url');

          $.ajax({
            type: 'GET',
            url: tabAjaxContainerUrl,
            success: function (result){
              if (result.success){
                tabAjaxContainer.html(result.responseView);
              }
            }
          });
        }
      }
    });
  }
});

// to use js-show-more functionality,
// it is necessary to use a few more classes/properties related to class js-show-more:
// js-show-more-wrapper
// js-show-more-element
// data-text_expanded
// data-text_collapsed
// (you can find an example applied in questions_by_keywords.html.twig)
$(document).on('click', '.js-show-more', function (){
  const wrapper = $(this).parents('.js-show-more-wrapper:first');
  const showMoreBtn = wrapper.find('.js-show-more');
  const textExpanded = showMoreBtn.data('text_expanded');
  const textCollapsed = showMoreBtn.data('text_collapsed');

  wrapper.find('.js-show-more-element').each(function (){
    if ($(this).hasClass('active')){
      $(this).removeClass('active').hide();
    } else {
      $(this).addClass('active').show();
    }
  });

  if (wrapper.find('.js-show-more-element.active').length){
    showMoreBtn.text(textExpanded);
  } else {
    showMoreBtn.text(textCollapsed);
  }
});

$(document).on('click', '.js-readMore-parent', function (){
  const readMoreItem = $(this);
  const url = readMoreItem.data('url');
  const childrenContainer = readMoreItem.parents('.js-readMore-wrapper:first').find('.js-readMore-children');
  const status = readMoreItem.find('#answers-status');

  if (!readMoreItem.hasClass('readMore-parent--active')){
    if (childrenContainer.find('.readMore-child').length){
      childrenContainer.show();
      readMoreItem.addClass('readMore-parent--active');
    } else {
      $.ajax({
        type: 'GET',
        url: url,
        success: function (result){
          if (result.success){
            childrenContainer.html(result.responseView);
            readMoreItem.addClass('readMore-parent--active');
          }
        }
      });
    }
    status.html('Hide');
  } else {
    readMoreItem.removeClass('readMore-parent--active');
    childrenContainer.hide();
    status.html('Show');
  }
});

$(document).on('click', '.js-load-more', function (){
  const loadBtn = $(this);
  const url = loadBtn.data('url');

  $.ajax({
    type: 'GET',
    url: url,
    success: function (result){
      if (result.success){
        loadBtn.replaceWith(result.responseView);
      }
    }
  });
});

$(document).on('click', '.js-expand-row-details', function (){
  const target = $(this);
  if (target.hasClass('js-collapsed')){
    target.removeClass('js-collapsed').addClass('js-expanded');
    target.find('.js-collapsed-icon').hide();
    target.find('.js-expanded-icon').show();
    target.closest('tr').next('tr').slideDown('slow');
  } else {
    target.closest('tr').next('tr').hide();
    target.removeClass('js-expanded').addClass('js-collapsed');
    target.find('.js-expanded-icon').hide();
    target.find('.js-collapsed-icon').show();
  }
});

$(document).on({
  mouseenter: function (){
    $('#answer_message_block_' + $(this).parent().parent().attr('data-id')).removeClass('hide');
  },
  mouseleave: function (){
    $('#answer_message_block_' + $(this).parent().parent().attr('data-id')).addClass('hide');
  }
}, '.wiki_comment_textarea');

$(document).on('click', '.js-dropdown-btn', function (){
  const dropdownContent = $('.' + $(this).data('dropdown'));

  if (dropdownContent.hasClass('dropdown--active')){
    dropdownContent.hide();
    dropdownContent.removeClass('dropdown--active');
  } else {
    dropdownContent.show();
    dropdownContent.addClass('dropdown--active');
  }
});

$(document).on('click', '.js-fill-hidden-input .js-option', function (){
  const btnsGroup = $(this).parents('.js-fill-hidden-input:first');
  const hiddenInputId = btnsGroup.data('input_id');
  const hiddenInput = $('#' + hiddenInputId);
  btnsGroup.find('.js-option').removeClass('selected');
  $(this).addClass('selected');
  hiddenInput.value = $(this).data('value');
});
$(document).on('click', '.js-fill-hidden-input .js-option', function () {
  const btnsGroup = $(this).parents('.js-fill-hidden-input:first')
  const hiddenInputId = btnsGroup.data('input_id')
  const hiddenInput = $('#' + hiddenInputId)
  btnsGroup.find('.js-option').removeClass('selected')
  $(this).addClass('selected')
  hiddenInput.value = $(this).data('value')
})

$(document).on('click', '.js-search-param-add', function (e){
  const collection = $(this).closest('.search-params-container').find('.js-search-param-collection');
  const prototype = $.parseHTML($('#js-search-param_prototype').data('prototype'))[0].innerHTML;
  addPrototypeElement(collection, prototype, collection.attr('data-index'));
});
$(document).on('click', '.js-search-param-delete', function (e){
  const parentToRemove = e.target.closest('.js-search-param-item-child');
  parentToRemove.remove();
});

// Toggle another div

// $(document).on('click', '.js-toggle-open', function (e) {
//   $(this).parents('.search-param-item').remove();
// });

function toggleOpen(querySelector){
  const DOM = document.querySelector(querySelector);
  $(DOM).stop().slideDown();
}

function toggleClose(querySelector){
  const DOM = document.querySelector(querySelector);
  $(DOM).stop().slideUp();
}

function toggle(querySelector){
  const DOM = document.querySelector(querySelector);
  $(DOM).stop().slideToggle();
}

function toggleContentSelect (e, querySelectorHide) {
  $(querySelectorHide).hide()
  $(e.target.value).show()
  readMoreSetup()
}

$('.js-sidebar').on('click', '.js-notification-message-read', function (e) {
  if ($(e.target).hasClass('v2 avatar')) {
    return;
  }

  let url = $(this).data('url')
  let resultContainer = $(this).data('resultcontainer')
  let isRead = $(this).data('read')
  let href = $(this).data('href')

  if (!isRead) {
    $.post(url, function (response) {
      if (response.success) {
        $('#' + resultContainer).replaceWith(response.responseView)
        fillInvisibleContent($('#unread-notification-count-wrapper'))
      }
    })
  }

  if (href) {
    window.open(href, '_blank');
  }
})

function clearUnreadCount(){
  $("#unread-notification-count-wrapper").empty();
}

//Smooth scroll to function
function smoothScrollTo(selector) {
  $("html, body")

      .stop()
      .animate(
          { scrollTop: $(selector).offset().top - 90 },
          500,
          'swing'
      );
}

$(document).on('click', '.js-remove-file-input-ajax', function (e){
  e.preventDefault();

  const currentElement = $(this);
  const container = $(this).prev('.file-thumb');
  const wrapper = currentElement.parents('.js-multi-file-upload-wrapper');
  const uploadButton = wrapper.find('.js-upload-button');
  const fileInputId = $(this).data("parent");
  const fileInput = $("#"+fileInputId);

  const params = {
    url: currentElement.data('url'),
    element: currentElement,
    container: container
  }

  $.ajax({
    url: params.url,
    method: 'POST',
    beforeSend: function (){
    },
    success: function (result){
      if (result.success){
        params.element.remove();
        if (fileInput.data('max') && wrapper.find('.js-uploaded-files .js-remove-file-input-ajax').length < fileInput.data('max')) {
          uploadButton.removeClass('hide');
        }
      } else {
        alert(result.message)
      }
    },
    complete: function () {
    }
  });
});

$(document).on('click', '.js-show-hide-button', function (e){
  e.preventDefault();
  const jsTextInput = $(this).parent('.js-button-text-input-wrap').find('.js-show-hide-text-input');
  $(this).addClass('hide');
  jsTextInput.removeClass('hide').trigger('focus');
});

$(document).on('blur', '.js-show-hide-text-input', function (e){
  e.preventDefault();
  const jsButton = $(this).parent('.js-button-text-input-wrap').find('.js-show-hide-button');
  $(this).addClass('hide');
  jsButton.removeClass('hide');
});

$(document).on('click', '.js-mobile-avatar-show-all', function () {
  let target = $(this).closest('.js-mobile-avatar-show-all');
  $(this).hide();
  target.next('.mobile-avatar__all').show();
});

$(document).on('click', '.js-mobile-avatar-show-few', function () {
  let target = $(this).closest('.js-mobile-avatar-show-few');
  target.parent('.mobile-avatar__all').hide();
  target.parent().prev('.mobile-avatar__few').show();

  // scroll to the top of element - TODO: to be refactored after scrollToElement() is renamed
  $('html, body').animate({
    scroll: $(this).closest('.feed-item')
  }, 1000);

});

$(document).on('click', '.js-remove-existed-file', function (e) {
  $(this).parents('.upload--file--container').remove()
  $('#' + $(this).data('hidden_element')).val('1')
});

$(document).on('click', '.js-dropdown-action', function () {
  let dropdownContainer = $(this).closest(".basic-dropdown__container");
  if (dropdownContainer.find('.js-dropdown-items-changed').length > 0) {
    window.location.reload();
  }
});

$(document).on('click', '.js-newsfeed-filter-dropdown', function(e) {
  e.stopPropagation();
});

$(document).on('click', '.js-dropdown-item-action', function () {
  let dropdownItem = $(this);
  if (dropdownItem.hasClass('dropdown-item__selected')) {
    dropdownItem.removeClass('dropdown-item__selected')
    dropdownItem.find('.dropdown-item-check').hide();
  } else {
    dropdownItem.addClass('dropdown-item__selected');
    dropdownItem.find('.dropdown-item-check').show();
  }
  dropdownItem.addClass('js-dropdown-items-changed');
});

function ajaxCallFilterCheck(element) {
  let dropdownItem = $(element);
  let url = dropdownItem.data('url');
  let method = dropdownItem.data('method') ? dropdownItem.data('method') : 'GET';

  // ajax call
  $.ajax({
    url: url,
    method: method,
    success: function (result) {
      if (!result.success){
        alert(result.message);
      } else {
        window.location.reload();
      }
    }
  });
}

$(document).on('click', '.js-newsfeed-filter-apply', function() {
  let itemChanged = $('.js-dropdown-items-changed');
  if(itemChanged.length) {
    itemChanged.each((i, el) => {
      if($(el).hasClass('dropdown-item__selected')) {
        if($(el).hasClass('prev-checked')) {
          return;
        } else {
          ajaxCallFilterCheck($(el));
        }
      } else {
        if($(el).hasClass('prev-checked')) {
          ajaxCallFilterCheck($(el));
        } else {
          return;
        }
      }
    });
  }
  $('.js-newsfeed-filter-cancel').trigger('click');
});

$(document).on('click', '.js-newsfeed-filter-cancel', function() {
  let dropdownItem = $('.js-dropdown-item-action');
  dropdownItem.each((i, item) => {
    if($(item).hasClass('prev-checked')) {
      if(!$(item).hasClass('dropdown-item__selected')) {
        $(item).addClass('dropdown-item__selected');
        $(item).find('.dropdown-item-check').show();
      }
    } else {
      if($(item).hasClass('dropdown-item__selected')) {
        $(item).removeClass('dropdown-item__selected')
        $(item).find('.dropdown-item-check').hide();
      }
    }
    $(item).removeClass('js-dropdown-items-changed');
  });
  $('.js-newsfeed-filter-dropdown').parent().removeClass('active');
});

$(document).on('click', function(event) {
  const target = $(event.target);
  if (!target.closest('.js-newsfeed-filter-container').length) {
    $('.js-newsfeed-filter-dropdown').parent().removeClass('active');
    $('.js-newsfeed-filter-cancel').trigger('click');
  }
});

$(document).on('click', '.js-newsfeed-filter-container', function() {
  let self = $(this);
  if(!self.hasClass('active')) {
    self.removeClass('active');
    $('.js-newsfeed-filter-cancel').trigger('click');
  }
});

$(document).on('click', '.js-mobile-search-icon', function () {
  let openSearch = $(this).find('.js-open-header-search');
  let closeSearch = $(this).find('.js-close-header-search');
  let searchInput = $('.js-header-search-input');

  if (openSearch.is(':visible')) {
    openSearch.hide();
    closeSearch.show();
    $('.js-header-search-form-mobile').stop().slideDown("fast", "swing");
    $('#top').addClass('search-form-affected-style');
    searchInput.val('');
  } else {
    closeSearch.hide();
    openSearch.show();
    $('.js-header-search-form-mobile').stop().slideUp("fast", "swing");
    $('#top').removeClass('search-form-affected-style');
  }
});

$('.js-notification').on('click', function (e) {
  let ntf = $('#notification-container');
  if(!ntf.parent().hasClass('sidebar-area--opened')) {
    $('.js-sidebar').removeClass('sidebar-area--opened');
    ntf.parent().addClass('sidebar-area--opened');
    let url = $(this).data('url')
    let containerId = $(this).data('container')
    let containerElement = $('#'+containerId)
    $.ajax({
      url: url,
      method: 'GET',
      beforeSend: function () {
        blockElement(containerElement)
      },
      success: function (res) {
        containerElement.html(res.responseView)
        $('#unread-notification-count-wrapper').html('')
      },
      fail: function (xhr, textStatus, errorThrown) {
        openFoundationMessage("An error has occurred, please contact our office!");
      },
      complete: function () {
        unblockElement(containerElement)
      }
    })
  } else {
    ntf.parent().removeClass('sidebar-area--opened');
    fillInvisibleContent($('#unread-notification-count-wrapper'))
  }
})

$('.js-messages').on('click', function (e) {
  let msg = $('#messages-container');
  if(!msg.parent().hasClass('sidebar-area--opened')) {
    $('.js-sidebar').removeClass('sidebar-area--opened');
    msg.parent().addClass('sidebar-area--opened');

    $.ajax({
      url: $(this).data('url'),
      method: 'GET',
      beforeSend: function () {
        blockElement(msg);
      },
      success: function (res) {
        msg.html(res.responseView)
      },
      complete: function () {
        unblockElement(msg);
      }
    })
  } else {
    msg.parent().removeClass('sidebar-area--opened');
    reloadTotalMessages();
  }
});

// // Close individual dialog box
$(document).on('click', '.js-message-dialog-close', () => {
  let msgDialog = $('.js-dialog');
  msgDialog.addClass('message-dialog--close');
  closeDialogEventSource();
})

// modify to get correct dialog, messages etc
$(document).on('click', '.js-message-preview', function(e) {
  openMessageDialog($(this).data('url'));
});

$('#messages-container').on('mouseenter', '.js-message-preview', function () {
  $(this).find('.message-preview-timestamp').hide();
  $(this).find('.message-preview-actions').show();
}).on('mouseleave', '.js-message-preview', function () {
  $(this).find('.message-preview-actions').hide();
  $(this).find('.message-preview-timestamp').show();
});

$('#messages-container').on('mouseenter', '.message-preview-actions', function () {
  $($(this).data('dropdown')).show();
}).on('mouseleave', '.message-preview-actions', function () {
  $($(this).data('dropdown')).hide();
});

$('#messages-container').on('mouseenter', '.message-dialog-block-dropdown', function () {
  $(this).show();
}).on('mouseleave', '.message-dialog-block-dropdown', function () {
  $(this).hide();
});

$('#message-dialog').on('click', '.js-message-send', function (e) {
  e.preventDefault();
  let form = $(this).closest('form');
  $.ajax({
    url: $(this).data('url'),
    method: 'POST',
    data: form.serializeArray(),
    beforeSend: function () {
      blockElement(form);
    },
    success: function (res) {
      form.html(res.responseView);
    },
    complete: function () {
      unblockElement(form);
    }
  })
})

$(document).on('click', '.js-institutions-prompt-close', function () {
  $('.js-tutorial-background').remove();
});

$('body').on('change', '.js-discussion-private-toggle .switch input', function(e){
  const isChecked = $(e.target).is(':checked');
  const toggleField = document.getElementById($(e.target).closest('.js-discussion-private-toggle').data('togglefield'));
  if(isChecked){
    $(toggleField).show();
  }else{
    $(toggleField).hide();
  }
})

$(document).on('click', '.basic-dropdown__container',function(e){
  //$('.basic-dropdown__container.active').removeClass('active');
  // if($(e.target).hasClass('basic-dropdown__container')){
  //   $(e.target).addClass('active');
  // }else if($(e.target).closest('.basic-dropdown__container').length > 0){
  //   $($(e.target).closest('.basic-dropdown__container')[0]).addClass('active');
  // }
  let self = $(this);
  if(self.hasClass('active')) {
    self.removeClass('active');
  } else {
    $('.basic-dropdown__container').not(this).removeClass('active');
    self.addClass('active');
  }
});

// Toggle class on mouse over / out
$(document).on('mouseenter', '.js-hover-open-dropdown', function(e) {
  e.preventDefault();
  let _this = $(this);
  _this.addClass('active basic-dropdown__container');
});

$(document).on('mouseleave', '.js-hover-open-dropdown', function(e) {
  e.preventDefault();
  let container = $(this);
  let btn = container.find('.basic-dropdown__btn');

  if (btn.data('content_top')) {
    $(document).on('mouseenter', '.basic-dropdown__content', function() {
      container.addClass('active');
    });
    $(document).on('mouseleave', '.basic-dropdown__content', function() {
      container.removeClass('active basic-dropdown__container');
    });
  } else {
    container.removeClass('active basic-dropdown__container');
  }
});

$(document).on('click', function (e) {
  if ($(e.target).closest(".js-notification").length === 0 &&
    $(e.target).closest(".js-messages").length === 0 &&
    $(e.target).closest(".js-sidebar").length === 0) {
    $(".js-sidebar").removeClass('sidebar-area--opened');
  }

  if(!$(e.target).closest(".basic-dropdown__container").hasClass('active')) {
    $(".basic-dropdown__container").removeClass('active');
  }
});

$(document).on('click', '.js-toggle-password', function () {
  $(this).find('svg').toggleClass('hide');
  let input = $(this).parents('.js-toggle-password-wrapper').find('.js-toggle-password-target');
  if ("password" == input.attr("type")) {
    input.attr("type", "text");
  } else {
    input.attr("type", "password");
  }
});
$(document).on('autocomplete:readonly', function (e) {
  if ($(e.target).hasClass("js-clearable")){
    $(e.target).prop('readonly', true);
    $(e.target).prev().toggleClass('hide');
  }
});
$(document).on('click', '.js-clear-autocomplete', function () {
  $(this).toggleClass('hide');
  let input = $(this).next();
  input.val("");
  input.prop('readonly', false);

});
$(document).on('click', '.announcement .close-button', function (e) {
  $('.announcement').addClass('hide')
})

$(document).on('click', '.js-header-search-open', function() {
  $('.js-mobile-search-icon').trigger('click');
  dropdownMenu.hideIt();
})

$('.sidebar-area-messages #message-settings-receive-from').on('change', 'input', function(e) {
  let input = $(this);
  let url = $('#message-settings-receive-from').data('url');
  if (input.is(':checked')) {
    $.post(url, {value: input.val()}).done(function (data) {
      if (data.hasOwnProperty("flashMessage")) {
        FlashMessage.showShortMessage(data.flashMessage);
      }
      $('#message_setting-receive-from').val(input.val());
    });
  }
});

$(document).on('change', '.js-disable-email-frequency input[type="checkbox"]', function () {
  let inputElement = $(this);
  let data = inputElement.closest('.js-disable-email-frequency').data('enable-frequency');
  if(1 === data) {
    inputElement.closest('tr').find('select').prop('disabled', !inputElement.is(':checked'));
  }
});

$(document).on('change', '.js-disable-items-checkbox', function () {
  let isChecked = $(this).is(':checked');
  let secondFormContainer = $(this).closest('.js-disable-items-container').find('.js-disable-items-forms');
  let forms = secondFormContainer.find('input');
  let radioGroupDiv = secondFormContainer.find('.js-radio-group');

  forms.prop("disabled", !isChecked);
  radioGroupDiv.attr("disabled", !isChecked);
})
